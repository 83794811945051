/* base */
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { isInViewport } from "utils/isInViewport";
import { useTitle, useHoverDirty } from "react-use";

/* assets */
import { colors } from "theme/colors";

/* components */
import { Module } from "components/module";
import {
  Box,
  Boxes,
  Title,
  Section1,
  Subtitle,
  SectionTitle,
  MobileSpacer,
  Section2Boxes,
} from "modules/about/styles";
import { Soundwaves } from "modules/about/components/soundwaves";
import { TitleElement } from "modules/volunteer/components/titleelement";
import { SideSoundwaves } from "modules/about/components/sidesoundwaves";
import { Footer } from "components/footer";
import { ScrollIndicator } from "components/scrollindicator";

const background1 = `linear-gradient(180deg, ${colors.breachPurple} 80%, ${colors.white} 20%);-moz-linear-gradient(180deg, ${colors.breachPurple} 80%, ${colors.white} 20%);-o-linear-gradient(180deg, ${colors.breachPurple} 80%, ${colors.white} 20%);-webkit-linear-gradient(180deg, ${colors.breachPurple} 80%, ${colors.white} 20%);`;

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -200px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const vline = (
  <span style={{ fontWeight: 800, color: colors.breachPink }}>|</span>
);

const FunkyTitle = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    transform: rotate(15deg);
    animation: funkyColoredLettersBreach2023 1.5s infinite linear;

    &:nth-child(1) {
      animation-delay: 0.25s;
    }

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 0.75s;
    }

    &:nth-child(4) {
      animation-delay: 1s;
    }

    &:nth-child(5) {
      animation-delay: 1.25s;
    }

    &:nth-child(6) {
      animation-delay: 1.5s;
    }
  }
`;

export function BreachFestivalModule2023() {
  useTitle("Breach - Make Music Cyprus");

  const hoverContainer1 = React.useRef();
  const isHovering1 = useHoverDirty(hoverContainer1);
  const hoverContainer2 = React.useRef();
  const isHovering2 = useHoverDirty(hoverContainer2);
  const hoverContainer3 = React.useRef();
  const isHovering3 = useHoverDirty(hoverContainer3);

  function onScroll() {
    const anchors = document.getElementsByClassName("anchor");
    if (anchors) {
      const element1 = document.getElementById("anchor-1");
      if (element1) {
        const result1 = isInViewport(element1);
        if (result1) {
          anchors[0].classList.add("title-line-2");
        } else {
          anchors[0].classList.remove("title-line-2");
        }
      }
      const element2 = document.getElementById("anchor-2");
      if (element2) {
        const result2 = isInViewport(element2);
        if (result2) {
          anchors[1].classList.add("title-line-1-3");
        } else {
          anchors[1].classList.remove("title-line-1-3");
        }
      }
      const element3 = document.getElementById("anchor-3");
      if (element3) {
        const result3 = isInViewport(element3);
        if (result3) {
          anchors[2].classList.add("title-line-2");
        } else {
          anchors[2].classList.remove("title-line-2");
        }
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Breach Festival by Make Music Cyprus"
        />
      </Helmet>
      <Module background={background1}>
        <Section1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                gap: 10,
                marginTop: -53,
                transformOrigin: "top",
                transform: "rotate(180deg)",
              }}
            >
              <TitleElement color={colors.breachOrange} />
            </div>
            <TitleElement color={colors.breachOrange} />
          </div>
          <Title>
            <FunkyTitle>
              <div>B</div>
              <div>R</div>
              <div>E</div>
              <div>A</div>
              <div>C</div>
              <div>H</div>
            </FunkyTitle>
          </Title>
          <Subtitle>
            IMPROVISED & EXPERIMENTAL MUSIC FESTIVAL
            <br />
            2ND EDITION
          </Subtitle>
        </Section1>
        <ScrollIndicator color={colors.breachOrange} />
      </Module>
      <Reveal triggerOnce keyframes={customAnimation}>
        <Module
          responsiveHeight="fit-content"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          background={colors.white}
        >
          <SectionTitle>
            <h3 className="anchor" id="anchor-1">
              <b>IMPROVISED & EXPERIMENTAL</b>
              <br />
              MUSIC FESTIVAL IN NICOSIA
            </h3>
            <br />
            <h4>
              Breach is a festival dedicated to Improvisation
              <br />
              and Experimental music practices
            </h4>
          </SectionTitle>
          <Section2Boxes>
            <div
              className="section-2-box"
              //   ref={hoverContainer1}
              style={{
                color: colors.white,
                background: colors.breachPurple,
              }}
            >
              {!isHovering1 ? (
                <React.Fragment>
                  <h6>WHEN</h6>
                  <div>
                    19th & 20th
                    <br />
                    <br />
                    April 2023
                    <br />
                    <br />
                    8:00 PM
                  </div>
                </React.Fragment>
              ) : (
                <div className="paragraph-container">
                  <p></p>
                </div>
              )}
            </div>
            <div
              className="section-2-box"
              //   ref={hoverContainer2}
              style={{
                color: colors.white,
                background: colors.breachPurple,
              }}
            >
              {!isHovering2 ? (
                <React.Fragment>
                  <h6>WHERE</h6>
                  <div>
                    Theatro Polis OPAP
                    <br />
                    <br />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://goo.gl/maps/yYm1df3HEawXH68w6"
                      style={{
                        bottom: 35,
                        position: "absolute",
                        pointerEvents: "auto",
                        color: colors.breachPink,
                      }}
                    >
                      See location
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <div className="paragraph-container">
                  <p></p>
                </div>
              )}
            </div>
            <div
              className="section-2-box"
              //   ref={hoverContainer3}
              style={{
                color: colors.white,
                background: colors.breachPurple,
              }}
            >
              {!isHovering3 ? (
                <React.Fragment>
                  <h6>TICKETS</h6>
                  <div>
                    €10 {vline} day
                    <br />
                    <br />
                    €15 {vline} both days
                    <br />
                    <br />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.soldoutticketbox.com/breach-festival-2023"
                      style={{
                        bottom: 35,
                        position: "absolute",
                        pointerEvents: "auto",
                        color: colors.breachPink,
                      }}
                    >
                      Book tickets
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <div className="paragraph-container">
                  <p></p>
                </div>
              )}
            </div>
            <Soundwaves color={colors.breachPink} />
          </Section2Boxes>
          <MobileSpacer alwaysView />
        </Module>
      </Reveal>
      <Module
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        background={colors.breachPink}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            background: "url(/posters/breach-23.jpg)",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Module>
      <Reveal triggerOnce keyframes={customAnimation}>
        <Module
          display="flex"
          alignItems="center"
          smHeight="fit-content"
          minHeight="100vh"
          flexDirection="column"
          justifyContent="center"
          background={colors.white}
        >
          <MobileSpacer alwaysView />
          <SectionTitle oppositeLine>
            <h3 className="anchor" id="anchor-2">
              <b>BREACH</b>
              <br />
              MUSIC FESTIVAL
            </h3>
            <br />
            <h4>Improvised & Experimental</h4>
          </SectionTitle>
          <Boxes noCol huge>
            <Box id="paragraph-text" color={colors.breachPurple}>
              Breach is a festival dedicated to Improvisation and Experimental
              music practices. New and alternative musical styles have been
              spread worldwide in the latter half of the 20th century. Breach's
              mission is to boost and support these practices in Cyprus: free
              improvisation, free jazz, contemporary classical music,
              experimental electronic music, sound-art, as well as many other
              uncharted sound territories. The genre of improvised music has
              been a constant in the music world since 1960, yet its
              appreciation in the eastern tip of the Mediterranean basin
              remains, at best, lifeless, sporadic and dull. Today, many
              musicians from the region who practice free improvisation excel
              worldwide, creating original work and collaborating with musicians
              of international renown,
            </Box>
            <Box id="paragraph-text" color={colors.breachPurple}>
              but sadly they have few opportunities to present their work in
              their homeland. Breach Festival's goal, is on the one hand, to
              introduce and establish the improvisation idiom, bringing to the
              region a form of musical communication, and on the other, create
              the proper channels and conditions in order to foster fruitful
              artistic development of the idiom, give the opportunity to artists
              to present their work and establish new musical collaborations,
              throughout workshops, lessons, music series and concerts. This
              year is the first edition of the Breach Festival and it has joined
              forces with the Irtijal festival in Lebanon - the largest festival
              in Beirut which celebrates its 20th anniversary of supporting
              improvised and experimental.
            </Box>
            <SideSoundwaves
              color={colors.breachPink}
              position="left: -48%;bottom: 60%;"
            />
          </Boxes>
        </Module>
      </Reveal>
      <MobileSpacer alwaysView color={colors.white} />
      <Footer style={{ borderTopColor: colors.bgwhite }} />
    </React.Fragment>
  );
}
