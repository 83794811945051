import React from "react";
import { SignupDiv, InvertedStyledNavLink } from "./styles";

export function SignupButton(props) {
  const [isHovered, setIsHovered] = React.useState({ x: 0, y1: 0, y2: 0 });

  function onMouseOver(e) {
    e.stopPropagation();
    const bounds = document
      .getElementById("sign-up-nav-link")
      .getBoundingClientRect();
    let x = e.clientX - bounds.left;
    let y = e.clientY - bounds.top;
    y = y > 17 ? y : y - 35;
    x = x > 40 ? x - 40 : x - 80;
    setIsHovered({ x: x / 5, y1: y / 5, y2: (y * -1) / 5 });
  }

  function onMouseOut(e) {
    setIsHovered({ x: 0, y1: 0, y2: 0 });
  }

  const transform1 = {
    transform: `translate3d(${isHovered.x}px, ${isHovered.y1}px, 0px)`,
  };

  const transform2 = {
    transform: `translate3d(${isHovered.x}px, ${isHovered.y2}px, 0px)`,
  };

  return (
    <SignupDiv style={{ visibility: props.show ? "visible" : "hidden" }}>
      <InvertedStyledNavLink
        to="/festival/sign-up/perform"
        id="sign-up-nav-link"
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
      >
        <span style={transform1}>S</span>
        <span style={transform2}>I</span>
        <span style={transform1}>G</span>
        <span style={transform2}>N</span>
        <span style={{ margin: "0 3px" }} />
        <span style={transform1}>U</span>
        <span style={transform2}>P</span>
      </InvertedStyledNavLink>
    </SignupDiv>
  );
}
