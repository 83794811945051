/* eslint-disable react-hooks/exhaustive-deps */
/* base */
import React from "react";
import { Module } from "components/module";
import { GraphQLClient, ClientContext } from "graphql-hooks";
import { BrowserRouter, Switch, Route, Link, Redirect } from "react-router-dom";

/* modules */
import { colors } from "theme/colors";
import { HomeModule } from "modules/home";
import { AboutModule } from "modules/about";
import { GalleryModule } from "modules/gallery/experimental";
import { SupportModule } from "modules/support";
import { VolunteerModule } from "modules/volunteer";
import { ParticipateModule } from "modules/participate";
import { ContactModule } from "modules/contact";
import { HeaderComponent } from "components/header";
import { Footer } from "components/footer";
import { Map } from "modules/map";
import { LiveStream } from "modules/live-stream";
import { BreachFestivalModule } from "modules/org-projects/project-pages/breach/2024";
// import { useFirstLoad } from "hooks/useFirstLoad";
import { SignUpPerform } from "modules/sign-up";
import Programme from "modules/programme/versions/2023";
import { LineUp } from "modules/line-up";
import { festivalNavLinks, orgNavLinks } from "components/header/data";
import { OrgLandingModule } from "modules/org-landing";
import { OrgProjectsModule } from "modules/org-projects";
import { BreachFestivalModule2022 } from "modules/org-projects/project-pages/breach/2022";
import { BreachFestivalModule2023 } from "modules/org-projects/project-pages/breach/2023";

const client = new GraphQLClient({
  url: "https://graphql.datocms.com/",
  headers: {
    Authorization: "Bearer 8e5496b7fb27e177875fe5ed092e80",
  },
});

const comingsection = (phrase) => (
  <React.Fragment>
    <Module background={colors.orange1}>
      <div
        style={{
          height: "100%",
          display: "flex",
          fontSize: "5em",
          color: "#ffffff",
          padding: "0 10vw",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ textAlign: "center" }}>{phrase}</span>
      </div>
    </Module>
    <Footer />
  </React.Fragment>
);

function NoMatchModule() {
  return (
    <React.Fragment>
      <Module background={colors.orange1}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            fontSize: "5em",
            color: "#ffffff",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>
            <b>OOPS!</b>
          </span>
        </div>
      </Module>
      <Footer />
    </React.Fragment>
  );
}

const RedirectOut = (props) => {
  React.useEffect(() => {
    window.location = props.url;
  }, []);

  return null;
};

function FestivalRoutes() {
  return (
    <React.Fragment>
      <HeaderComponent
        showSignupBtn
        logoNavLink="/"
        navLinks={festivalNavLinks}
      />
      <Switch>
        <Route exact path="/festival">
          <HomeModule />
        </Route>
        <Route path="/festival/about-festival">
          <AboutModule />
        </Route>
        <Route path="/festival/participate">
          <ParticipateModule />
        </Route>
        <Route path="/festival/volunteer">
          <VolunteerModule />
        </Route>
        <Route path="/festival/support">
          <SupportModule />
        </Route>
        <Route path="/festival/gallery">
          <GalleryModule />
        </Route>
        <Route path="/festival/contact">
          <ContactModule />
        </Route>
        <Route path="/festival/lineup">
          <LineUp />
        </Route>
        <Route path="/festival/lineup-2023">
          <Programme />
          <Footer />
        </Route>
        <Route path="/festival/2023-program">
          <Redirect to="/lineup-2023" />
        </Route>
        <Route path="/festival/festival/map">
          <Map />
          <Footer />
        </Route>
        <Route path="/festival/2022-program">
          <Programme />
          <Footer />
        </Route>
        <Route path="/festival/2022-map">
          <Map />
          <Footer />
        </Route>
        <Route path="/festival/global-live-stream">
          <LiveStream />
        </Route>
        <Route path="/festival/sign-up/perform">
          {/* {comingsection(
            <>
              THANK YOU FOR YOUR SUBMISSIONS <b>SEE YOU ON JUNE 21!</b>
            </>
          )} */}
          <SignUpPerform />
        </Route>
        <Route path="/festival/sign-up/host">
          {comingsection(
            <>
              THE <b>HOST</b> APPLICATION WILL BE AVAILABLE SOON
            </>
          )}
        </Route>
        <Route path="/festival/sign-up/lead">
          {comingsection(
            <>
              THE <b>LEAD</b> APPLICATION WILL BE AVAILABLE SOON
            </>
          )}
        </Route>
        <Route path="/festival/donate">{comingsection(<>COMING SOON</>)}</Route>
        <Route path="*">
          <NoMatchModule />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

function App() {
  // useFirstLoad();
  return (
    <ClientContext.Provider value={client}>
      <BrowserRouter>
        <Switch>
          <Route path="/festival">
            <FestivalRoutes />
          </Route>
          <Route path="/">
            <Switch>
              <Route exact path="/">
                <OrgLandingModule />
              </Route>
              <Route path="/qr-code">
                <Redirect to="/lineup" />
              </Route>
              <Route exact path="/breach-festival">
                {/* <BreachFestivalModule /> */}
                <RedirectOut url="https://www.instagram.com/breach_festival" />
              </Route>
              <Route path="/:page">
                <HeaderComponent logoNavLink="/" navLinks={orgNavLinks} />
                <Switch>
                  <Route exact path="/contact">
                    <ContactModule hideTeamSection />
                  </Route>
                  <Route exact path="/projects">
                    <OrgProjectsModule />
                  </Route>
                  <Route exact path="/projects/breach/2022">
                    <BreachFestivalModule2022 />
                  </Route>
                  <Route exact path="/projects/breach/2023">
                    <BreachFestivalModule2023 />
                  </Route>
                  <Route exact path="/projects/breach/2024">
                    <BreachFestivalModule />
                  </Route>
                  <Route path="*">
                    <NoMatchModule />
                  </Route>
                </Switch>
              </Route>
              <Route path="*">
                <NoMatchModule />
              </Route>
            </Switch>
          </Route>
          <Route path="*">
            <NoMatchModule />
          </Route>
        </Switch>
      </BrowserRouter>
    </ClientContext.Provider>
  );
}

export default App;
