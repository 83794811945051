/* base */
import React from "react";
import get from "lodash/get";
import styled from "styled-components";
import { useQuery } from "graphql-hooks";
import { useHistory } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import useLocalStorageState from "use-local-storage-state";

/* assets */
import { colors } from "theme/colors";
import { fontsizes } from "theme/fontsizes";
import { YoutubeIcon } from "icons/Youtube";
import { FacebookIcon } from "icons/Facebook";
import { InstagramIcon } from "icons/Instagram";
import { LoaderComponent } from "components/loader";

const Container = styled.footer`
  height: 100px;
  display: flex;
  padding: 15px 5vw;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.color};
  border-top: 1px solid ${(props) => props.textColor || colors.white};

  > div {
    display: flex;
    text-align: start;
    font-size: ${fontsizes.footer};
    color: ${(props) => props.textColor || colors.white};

    > a {
      margin-right: 16px;

      svg {
        width: 32px;
        height: 32px;

        path {
          fill: ${(props) => props.textColor || colors.white} !important;
        }
      }
    }
  }

  > div:nth-of-type(2) {
    opacity: 0.9;
  }

  @media (max-width: 499px) {
    > div {
      > br {
        // display: none;
      }
    }
  }
`;

const bgcolorperpage = {
  "/": colors.black,
  "/projects": colors.black,
  "/projects/breach/2022": colors.breachBlue,
  "/projects/breach/2023": colors.breachPurple,
  "/projects/breach/2024": colors.white,
  "/festival": colors.orange1,
  "/festival/about-festival": colors.bgblue1,
  "/festival/gallery": colors.bggrey1,
  "/festival/participate": colors.bgred1,
  "/festival/volunteer": colors.orange2,
  "/festival/volunteer/": colors.orange2,
  "/festival/support": colors.bgturquoise1,
  "/festival/contact": colors.orange1,
  "/festival/sign-up": colors.orange1,
};

const BASE_FOOTER_QUERY = `query Footer($lang: SiteLocale) {
  footer {
    copyrightNote(locale: $lang)
  }
}`;

export const Footer = (props) => {
  const history = useHistory();
  const color = bgcolorperpage[history.location.pathname] || colors.orange1;

  // const lang = useLocalStorageState("lang", { defaultValue: "en" })[0];
  const lang = "en";
  const { loading, data, refetch } = useQuery(BASE_FOOTER_QUERY, {
    variables: { lang: lang || "en" },
  });

  useUpdateEffect(() => {
    refetch({
      lang: lang || "en",
    });
  }, [lang]);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <Container
      color={color}
      style={props.style || {}}
      textColor={props.textColor}
    >
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/makemusiccyprus"
        >
          <FacebookIcon />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/makemusiccyprus"
        >
          <InstagramIcon />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/channel/UCxVjAVoep762kKYJohjq_lA"
        >
          <YoutubeIcon />
        </a>
      </div>
      <div>
        {get(data, "footer.copyrightNote", "")
          .split("\n")
          .map((s) => (
            <React.Fragment key={s}>
              {s.replace("{year}", new Date().getFullYear())}
              <br />
            </React.Fragment>
          ))}
      </div>
    </Container>
  );
};
