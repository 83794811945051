/* base */
import React from "react";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import { useQuery } from "graphql-hooks";
import { NavLink } from "react-router-dom";
import { isInViewport } from "utils/isInViewport";
import { useTitle, useUpdateEffect } from "react-use";
import useLocalStorageState from "use-local-storage-state";

/* assets */
import { colors } from "theme/colors";

/* components */
import { Module } from "components/module";
import { Footer } from "components/footer";
import { LoaderComponent } from "components/loader";
import { Title, Section1, Subtitle } from "modules/about/styles";
import {
  Section2,
  Section3,
  Section4,
  Section2Up,
  Section2Down,
  Section3Down,
  Section4Down,
  Section2Text,
  SoundwavesIcon,
} from "modules/participate/styles";
import { Card } from "./components/card";
import { TitleElement } from "./components/titleelement";
import { SignalIcon } from "./components/animateicons/signal";
import { TitleElement as Soundwaves } from "modules/about/components/titleelement";
import { LeadIcon } from "./components/animateicons/lead";
import { ScrollIndicator } from "components/scrollindicator";

const background1 = `linear-gradient(180deg, ${colors.bgred1} 80%, ${colors.white} 20%);-moz-linear-gradient(180deg, ${colors.bgred1} 80%, ${colors.white} 20%);-o-linear-gradient(180deg, ${colors.bgred1} 80%, ${colors.white} 20%);-webkit-linear-gradient(180deg, ${colors.bgred1} 80%, ${colors.white} 20%);`;
const background2 = `linear-gradient(180deg, ${colors.white} 50%, ${colors.bgred1} 50%);-moz-linear-gradient(180deg, ${colors.white} 50%, ${colors.bgred1} 50%);-o-linear-gradient(180deg, ${colors.white} 50%, ${colors.bgred1} 50%);-webkit-linear-gradient(180deg, ${colors.white} 50%, ${colors.bgred1} 50%);`;

const BASE_PARTICIPATE_QUERY = `query Participate($lang: SiteLocale) {
  participate {
    title(locale: $lang)
    subtitle(locale: $lang)
    section2Slogan1Segment1(locale: $lang)
    section2Slogan1Segment2(locale: $lang)
    section2Slogan1Segment3(locale: $lang)
    section2Slogan1Segment4(locale: $lang)
    section2Slogan1Segment5(locale: $lang)
    section2Slogan2Segment1(locale: $lang)
    section2Slogan2Segment2(locale: $lang)
    section2Slogan2Segment3(locale: $lang)
    section2Slogan2Segment4(locale: $lang)
    section3Box1Title(locale: $lang)
    section3Box1Subtitle(locale: $lang)
    section3Box1Text(locale: $lang)
    section3Box1Button(locale: $lang)
    section3Box2Title(locale: $lang)
    section3Box2Subtitle(locale: $lang)
    section3Box2Text(locale: $lang)
    section3Box2Button(locale: $lang)
    section3Box3Title(locale: $lang)
    section3Box3Subtitle(locale: $lang)
    section3Box3Text(locale: $lang)
    section3Box3Button(locale: $lang)
    section4Slogan1Segment1(locale: $lang)
    section4Slogan1Segment2(locale: $lang)
    section4Slogan1Segment3(locale: $lang)
    section4Slogan2Segment1(locale: $lang)
    section4Slogan2Segment2(locale: $lang)
    section4Button(locale: $lang)
  }
}`;

export function ParticipateModule() {
  useTitle("Participate - Make Music Cyprus");

  // const lang = useLocalStorageState("lang", {
  //   defaultValue: "en",
  // })[0];
  const lang = "en";
  const { loading, data, refetch } = useQuery(BASE_PARTICIPATE_QUERY, {
    variables: { lang: lang || "en" },
  });

  function onScroll() {
    const anchors = document.getElementsByClassName("anchor");
    if (anchors) {
      const element1 = document.getElementById("1-third-h3");
      if (element1) {
        const result1 = isInViewport(element1);
        if (result1) {
          anchors[0].classList.add("title-line-1-3");
        } else {
          anchors[0].classList.remove("title-line-1-3");
        }
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useUpdateEffect(() => {
    refetch({
      lang: lang || "en",
    });
  }, [lang]);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Make Music Cyprus - WE WELCOME YOU, MUSICIANS AND MUSIC LOVERS FROM ALL GENRES, CULTURES, AGES AND ABILITIES TO SHARE YOUR CREATIVITY! ON THIS SPECIAL DAY, MUSIC CAN POP UP ANYWHERE!"
        />
      </Helmet>
      <Module background={background1}>
        <Section1>
          <div style={{ margin: "-80px 0 15px 0" }}>
            <TitleElement color="#ffffff" />
          </div>
          <Title>{get(data, "participate.title", "PARTICIPATE")}</Title>
          <Subtitle>
            {get(data, "participate.title", "EVERYONE CAN BE A PART OF IT")}
          </Subtitle>
        </Section1>
        <ScrollIndicator color={colors.bgred1} />
      </Module>
      <Module background={background2}>
        <Section2>
          <Section2Up>
            <Section2Text>
              <b>
                {get(
                  data,
                  "participate.section2Slogan1Segment1",
                  "WE WELCOME YOU,"
                )}
              </b>
              <br />
              <b>
                {get(
                  data,
                  "participate.section2Slogan1Segment2",
                  "MUSICIANS AND MUSIC LOVERS FROM ALL"
                )}
              </b>
              {/* <b>MUSICIANS</b> AND <b>MUSIC LOVERS</b> FROM <b>ALL</b> */}
              <br />
              <b>
                {get(
                  data,
                  "participate.section2Slogan1Segment3",
                  "GENRES, CULTURES, AGES AND ABILITIES TO"
                )}
              </b>
              {/* <b>GENRES, CULTURES, AGES</b> AND <b>ABILITIES</b> TO */}
              <br />
              <b>
                {get(
                  data,
                  "participate.section2Slogan1Segment4",
                  "SHARE YOUR CREATIVITY! ON THIS SPECIAL DAY,"
                )}
              </b>
              {/* <b>SHARE YOUR CREATIVITY!</b> ON THIS SPECIAL DAY, */}
              <br />
              <b>
                {get(
                  data,
                  "participate.section2Slogan1Segment5",
                  "MUSIC CAN POP UP ANYWHERE!"
                )}
              </b>
            </Section2Text>
            <div
              id="participate-anim"
              style={{
                position: "absolute",
                right: "calc(-10vw - 40px)",
                transform: "scale(2.5)",
              }}
            >
              <TitleElement color={colors.bgred2} />
            </div>
          </Section2Up>
          <Section2Down>
            <Section2Text>
              <b>
                {get(
                  data,
                  "participate.section2Slogan2Segment1",
                  "WE ALSO INVITE COFFEE SHOPS,"
                )}
              </b>
              <br />
              <b>
                {get(
                  data,
                  "participate.section2Slogan2Segment2",
                  "RESTAURANTS, BARS, RETAIL STORES,"
                )}
              </b>
              <br />
              <b>
                {get(
                  data,
                  "participate.section2Slogan2Segment3",
                  "SCHOOLS TO OPEN YOUR DOORS"
                )}
              </b>
              <br />
              <b>
                {get(
                  data,
                  "participate.section2Slogan2Segment4",
                  "TO A MUSICAL EVENT!"
                )}
              </b>
            </Section2Text>
            <div
              id="participate-anim"
              style={{
                position: "absolute",
                left: "calc(-10vw - 40px)",
                transform: "scale(2.5)",
              }}
            >
              <TitleElement color={colors.white} />
            </div>
          </Section2Down>
        </Section2>
      </Module>
      <Module
        padding="0 0 5vh 0"
        height="fit-content"
        responsiveHeight="fit-content"
      >
        <Section3>
          <Section3Down>
            <Card
              title={get(data, "participate.section3Box1Title", "PERFORM")}
              link="/festival/sign-up/perform"
              subtitle={get(
                data,
                "participate.section3Box1Subtitle",
                "There is always space for you!"
              )}
              icon={
                <SoundwavesIcon>
                  <Soundwaves />
                </SoundwavesIcon>
              }
              body={get(
                data,
                "participate.section3Box1Text",
                "Everyone, regardless of age, ability, or preferred musical style can participate. We encourage you to embrace your inner musician, share music freely, and celebrate the rich musical diversity of your communities on June 21st."
              )}
              button={get(data, "participate.section3Box1Button", "SIGN UP")}
            />
            <Card
              title={get(data, "participate.section3Box2Title", "HOST")}
              link="/sign-up/host"
              subtitle={get(
                data,
                "participate.section3Box2Subtitle",
                "There will be musicians for you!"
              )}
              icon={<SignalIcon />}
              body={get(
                data,
                "participate.section3Box2Text",
                "Any location in the city can host a performance or participatory event as long as it is free and accessible to the public. Make Music Cyprus is a unique way to activate your space, engage your community, and inspire anyone and everyone to discover the joy of making music."
              )}
              button={get(data, "participate.section3Box2Button", "SIGN UP")}
            />
            <Card
              title={get(data, "participate.section3Box3Title", "LEAD")}
              link="/sign-up/lead"
              subtitle={get(
                data,
                "participate.section3Box3Subtitle",
                "There will be people for you!"
              )}
              icon={<LeadIcon />}
              body={get(
                data,
                "participate.section3Box3Text",
                "We have countless interactive, mass-appeal activities waiting for someone to take the lead. We welcome your own ideas too. On this special day, music can pop up anywhere and in any form!"
              )}
              button={get(data, "participate.section3Box3Button", "SIGN UP")}
            />
          </Section3Down>
        </Section3>
      </Module>
      <Module responsiveHeight="fit-content">
        <Section4>
          <Section2>
            <Section2Up>
              <Section2Text>
                <b>
                  {get(
                    data,
                    "participate.section4Slogan1Segment1",
                    "DO YOU HAVE A MUSICAL IDEA/PROJECT"
                  )}
                </b>
                <br />
                {get(
                  data,
                  "participate.section4Slogan1Segment2",
                  "YOU WANT TO SEE IT LIVE?"
                )}
                <br />
                <b>
                  {get(
                    data,
                    "participate.section4Slogan1Segment3",
                    "MAKE MUSIC CYPRUS CAN SUPPORT IT!"
                  )}
                </b>
              </Section2Text>
            </Section2Up>
          </Section2>
          <Section4Down>
            <Section2Text id="1-third-h3" className="anchor">
              <b>
                {get(data, "participate.section4Slogan2Segment1", "QUESTIONS?")}
              </b>
              <br />
              {get(
                data,
                "participate.section4Slogan2Segment2",
                "We are here to answer them!"
              )}
            </Section2Text>
            <br />
            <br />
            <br />
            <br />
            <NavLink to="/festival/contact">
              {get(data, "participate.section4Button", "CONTACT US")}
            </NavLink>
            <br />
            <br />
            <br />
            <br />
            <div
              id="participate-anim"
              style={{
                right: "0",
                bottom: "150px",
                position: "absolute",
                transform: "scale(2.5)",
              }}
            >
              <TitleElement color="#fff" />
            </div>
          </Section4Down>
        </Section4>
      </Module>
      <Footer />
    </React.Fragment>
  );
}
