/* eslint-disable react-hooks/exhaustive-deps */
/* base */
import React from "react";
import get from "lodash/get";
import { useGA } from "hooks/useGA";
import { useQuery } from "graphql-hooks";
import { useWindowSize, useUpdateEffect } from "react-use";
import useLocalStorageState from "use-local-storage-state";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useDocumentScrollThrottled } from "hooks/useDocumentScrollThrottled";

/* style components */
import { colors } from "theme/colors";
import logo from "components/header/logo.svg";
import MapIcon from "components/header/map.png";
import { LoaderComponent } from "components/loader";
import LineupIcon from "components/header/lineup.png";
import { SignupButton } from "components/header/components/signupbtn";
import {
  Nav,
  Links,
  Container,
  MenuButton,
  LogoNavLink,
  FixedButtons,
  StyledNavLink,
} from "components/header/styles";

const MINIMUM_SCROLL = 0;

const bgcolorperpage = {
  "/": colors.black,
  "/projects": colors.black,
  "/projects/breach/2022": colors.breachBlue,
  "/projects/breach/2023": colors.breachPurple,
  "/projects/breach/2024": colors.black,
  "/festival": colors.orange1,
  "/festival/about-festival": colors.bgblue1,
  "/festival/gallery": colors.bggrey1,
  "/festival/participate": colors.bgred1,
  "/festival/volunteer": colors.orange2,
  "/festival/volunteer/": colors.orange2,
  "/festival/support": colors.bgturquoise1,
  "/festival/contact": colors.orange1,
  "/festival/sign-up": colors.orange1,
};

const BASE_HEADER_QUERY = `query Header($lang: SiteLocale) {
  allHeaderPages {
    id
    label(locale: $lang)
  }
}`;

export function HeaderComponent(props) {
  useGA();
  const size = useWindowSize();
  const history = useHistory();
  const [lang1, setLang] = useLocalStorageState("lang", { defaultValue: "en" });
  const lang = "en";
  const [showLangSelector, setShowLangSelector] = React.useState(
    history.location.pathname === "/lineup"
  );
  const { loading, data, refetch } = useQuery(BASE_HEADER_QUERY, {
    variables: { lang: lang || "en" },
  });

  const [hideNav, setHideNav] = React.useState(size.width < 768);
  const [hideLogo, setHideLogo] = React.useState(false);
  const [showHiddenNav, setShowHiddenNav] = React.useState(false);
  const [showLineup, setShowLineup] = React.useState(
    // history.location.pathname !== "/lineup"
    false
  );
  const [showMap, setShowMap] = React.useState(
    // history.location.pathname !== "/map"
    false
  );

  useDocumentScrollThrottled((callbackData) => {
    if (size.width > 767 || !hideNav || (hideNav && size.width < 768)) {
      const { currentScrollTop } = callbackData;
      const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;
      setHideLogo(isMinimumScrolled);
      if (size.width > 767) {
        setHideNav(isMinimumScrolled);
        if (isMinimumScrolled) setShowHiddenNav(false);
      }
    }
  });

  React.useEffect(() => {
    if (size.width < 768 && !hideNav) {
      setHideNav(true);
    }
    if (size.width > 991) {
      setHideLogo(true);
    }
  }, [size]);

  React.useEffect(() => {
    return history.listen(() => {
      setTimeout(() => {
        if (size.width < 768) {
          setShowHiddenNav(false);
        }
        if (
          history.location.hash !== "#subscribe" &&
          history.location.hash !== "#form" &&
          history.location.hash.indexOf("#spot") !== 0
        ) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
        setShowLineup(history.location.pathname !== "/lineup");
        setShowMap(history.location.pathname !== "/map");
        setShowLangSelector(history.location.pathname === "/lineup");
      }, 5);
    });
  }, [history]);

  useUpdateEffect(() => {
    refetch({
      lang: lang || "en",
    });
  }, [lang]);

  function onLanguageChange(e) {
    setLang(e.target.value);
  }

  const color = bgcolorperpage[history.location.pathname] || colors.orange1;

  const ICON_SIZE = 13;

  const navlinks = React.useMemo(() => {
    if (history.location.pathname.indexOf("/projects/") > -1) {
      return [
        {
          name: "HOME",
          path: "/",
        },
        {
          name: "PROJECTS",
          path: "/projects",
        },
        {
          name: "CONTACT",
          path: "/contact",
        },
      ];
    }
    if (history.location.pathname === "/projects") {
      return [
        {
          name: "HOME",
          path: "/",
        },
        {
          name: "CONTACT",
          path: "/contact",
        },
      ];
    }
    if (history.location.pathname === "/contact") {
      return [
        {
          name: "HOME",
          path: "/",
        },
        {
          name: "PROJECTS",
          path: "/projects",
        },
      ];
    }
    return props.navLinks;
  }, [props.navLinks, history.location.pathname]);

  if (history.location.pathname === "/breach-festival") {
    return null;
  }

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <React.Fragment>
      {history.location.pathname.indexOf("/festival") > -1 && (
        <FixedButtons alternatePos={!showLineup || !showMap}>
          {showMap && (
            <Link to="/map">
              <img
                src={MapIcon}
                alt="map icon"
                width={ICON_SIZE}
                height={ICON_SIZE}
              />{" "}
              MAP
            </Link>
          )}
          {showLineup && (
            <Link to="/lineup">
              <img
                alt="map icon"
                src={LineupIcon}
                width={ICON_SIZE}
                height={ICON_SIZE}
              />{" "}
              LINEUP
            </Link>
          )}
        </FixedButtons>
      )}
      <LogoNavLink to={props.logoNavLink}>
        <img
          alt="logo"
          style={{
            position: "fixed",
            transition: "all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1)",
            left: "5vw",
            top:
              size.width < 992 && (!hideLogo || showHiddenNav)
                ? "32px"
                : "-100px",
          }}
          src={logo}
        />
      </LogoNavLink>
      <Container hideNav={hideNav} showHiddenNav={showHiddenNav} color={color}>
        <Nav
          hideNav
          shrinkLogo
          hide={showHiddenNav ? false : hideNav}
          color={color}
        >
          {size.width > 991 && (
            <NavLink to={props.logoNavLink}>
              <img alt="logo" src={logo} />
            </NavLink>
          )}
          <Links>
            {navlinks.map((link) => (
              <StyledNavLink to={link.path} key={link.path}>
                {link.name}
              </StyledNavLink>
            ))}
          </Links>
          {showLangSelector && size.width > 768 && (
            <select
              value={lang1}
              onChange={onLanguageChange}
              style={{
                outline: "none",
                color: colors.white,
                borderColor: colors.white,
                background: "transparent",
              }}
            >
              <option value="en">EN</option>
              <option value="el">ΕΛ</option>
            </select>
          )}
          <SignupButton show={props.showSignupBtn} />
        </Nav>
      </Container>
      {showLangSelector && size.width < 768 && (
        <div
          style={{
            top: "53px",
            zIndex: 999,
            right: "100px",
            position: "fixed",
            background: colors.black,
          }}
        >
          <select
            value={lang1}
            onChange={onLanguageChange}
            style={{
              outline: "none",
              color: colors.white,
              borderColor: colors.white,
              background: "transparent",
            }}
          >
            <option value="en">EN</option>
            <option value="el">ΕΛ</option>
          </select>
        </div>
      )}
      <MenuButton
        show={!hideNav}
        showHiddenNav={showHiddenNav}
        onClick={() => setShowHiddenNav(!showHiddenNav)}
        color={color}
      >
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </MenuButton>
    </React.Fragment>
  );
}
