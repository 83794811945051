export const orgNavLinks = [
  {
    name: "PROJECTS",
    path: "/projects",
  },
  {
    name: "CONTACT",
    path: "/contact",
  },
];

export const festivalNavLinks = [
  {
    name: "HOME",
    path: "/festival",
  },
  {
    name: "ABOUT",
    path: "/festival/about-festival",
  },
  {
    name: "PARTICIPATE",
    path: "/festival/participate",
  },
  {
    name: "VOLUNTEER",
    path: "/festival/volunteer",
  },
  {
    name: "SUPPORT",
    path: "/festival/support",
  },
  {
    name: "GALLERY",
    path: "/festival/gallery",
  },
  {
    name: "CONTACT",
    path: "/festival/contact",
  },
];
