/* base */
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { useTitle, useScroll } from "react-use";
import useMediaQuery from "@mui/material/useMediaQuery";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

/* components */
import { colors } from "theme/colors";
import { Chevron } from "icons/Chevron";
import { Footer } from "components/footer";
import { useBgImageSkeletonLoader } from "hooks/useBgImageSkeletonLoader";
import {
  BgAnimation,
  DiagonalDiv,
  DiagonalContainer,
} from "modules/org-landing/styles";

export function OrgProjectsModule() {
  useTitle("Projects - Make Music Cyprus");

  const scrollRef = React.useRef(null);
  const scrollableContainerCoords = useScroll(scrollRef);

  const lgUp = useMediaQuery("(min-width:992px)");

  const imageMMCLoaded = useBgImageSkeletonLoader({
    imageUrl: "/posters/mmc24.png",
    elementId: "diagonal-div-mmc",
  });
  const imageBreach1Loaded = useBgImageSkeletonLoader({
    imageUrl: "/posters/breach-22.jpg",
    elementId: "diagonal-div-breach-1",
  });
  const imageBreach2Loaded = useBgImageSkeletonLoader({
    imageUrl: "/posters/breach-poster.png",
    elementId: "diagonal-div-breach-2",
  });
  const imageBreach3Loaded = useBgImageSkeletonLoader({
    imageUrl: "/posters/breach-poster.webp",
    elementId: "diagonal-div-breach-3",
  });

  function onMobileChevronClick() {
    const target = document.getElementById("diagonal-container");
    if (target) {
      target.scrollTo({
        left: target.clientWidth,
        behavior: "smooth",
      });
    }
  }

  return (
    <React.Fragment>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "relative",
          background: colors.black,
        }}
      >
        <BgAnimation>
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
        </BgAnimation>
        <DiagonalContainer
          count={2}
          ref={scrollRef}
          id="diagonal-container"
          style={{ paddingTop: 130, overflowX: lgUp ? "hidden" : "auto" }}
        >
          {lgUp && (
            <DiagonalDiv
              to="/"
              noTopValue
              background="transparent"
              style={{ pointerEvents: "none" }}
            />
          )}
          <DiagonalDiv noTopValue to="/festival" background={colors.orange1}>
            <span
              id="diagonal-div-mmc"
              style={{
                width: "100%",
                height: "100%",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: imageMMCLoaded ? "block" : "none",
              }}
            />
            {!imageMMCLoaded && (
              <Skeleton
                width="100%"
                height="100%"
                animation="wave"
                variant="rectangular"
                sx={{ bgcolor: "grey.900" }}
              />
            )}
            <span id="header-on-hover">mmc</span>
            <div>
              <div>MMC</div>
            </div>
          </DiagonalDiv>
          <DiagonalDiv
            noTopValue
            to="/projects/breach/2022"
            background={colors.breachGreen}
          >
            <span
              id="diagonal-div-breach-1"
              style={{
                width: "100%",
                height: "100%",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: imageBreach1Loaded ? "block" : "none",
              }}
            />
            {!imageBreach1Loaded && (
              <Skeleton
                width="100%"
                height="100%"
                animation="wave"
                variant="rectangular"
                sx={{ bgcolor: "grey.900" }}
              />
            )}
            <span id="header-on-hover">Breach 2022</span>
            <div>
              <div>breach '22</div>
            </div>
          </DiagonalDiv>
          <DiagonalDiv
            noTopValue
            to="/projects/breach/2023"
            background={colors.breachPink}
          >
            <span
              id="diagonal-div-breach-2"
              style={{
                width: "100%",
                height: "100%",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: imageBreach2Loaded ? "block" : "none",
              }}
            />
            {!imageBreach2Loaded && (
              <Skeleton
                width="100%"
                height="100%"
                animation="wave"
                variant="rectangular"
                sx={{ bgcolor: "grey.900" }}
              />
            )}
            <span id="header-on-hover">Breach 2023</span>
            <div>
              <div>breach '23</div>
            </div>
          </DiagonalDiv>
          <DiagonalDiv
            noTopValue
            to="/projects/breach/2024"
            background={colors.white}
          >
            <span
              id="diagonal-div-breach-3"
              style={{
                width: "100%",
                height: "100%",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: imageBreach3Loaded ? "block" : "none",
              }}
            />
            {!imageBreach3Loaded && (
              <Skeleton
                width="100%"
                height="100%"
                animation="wave"
                variant="rectangular"
                sx={{ bgcolor: "grey.900" }}
              />
            )}
            <span id="header-on-hover">Breach 2024</span>
            <div>
              <div>breach '24</div>
            </div>
          </DiagonalDiv>
          {lgUp && (
            <DiagonalDiv
              to="/"
              noTopValue
              background="transparent"
              style={{ pointerEvents: "none" }}
            />
          )}
        </DiagonalContainer>
      </div>
      <div
        onClick={onMobileChevronClick}
        style={{
          right: 30,
          bottom: 30,
          height: 24,
          zIndex: 100,
          position: "fixed",
          cursor: "pointer",
          display: scrollableContainerCoords.x === 0 ? "block" : "none",
          animation: "scroll-indicator-horizontal 1.5s infinite linear",
        }}
        className="scroll-horizontal-button"
      >
        <Chevron fill={colors.white} style={{ transform: "scale(1.5)" }} />
      </div>
      <Footer style={{ borderTopColor: colors.bgwhite }} />
    </React.Fragment>
  );
}
