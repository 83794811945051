/* base */
import React from "react";
import { useScroll } from "react-use";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { isTouchDevice } from "utils/isTouchDevice";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import useLocalStorageState from "use-local-storage-state";

/* style components */
import { colors } from "theme/colors";
import { Chevron } from "icons/Chevron";
import { Footer } from "components/footer";
import { fontsizes } from "theme/fontsizes";
import logo from "components/header/logo.svg";
import { Container } from "modules/about/components/soundwaves/styles";
import { useBgImageSkeletonLoader } from "hooks/useBgImageSkeletonLoader";
import {
  BgAnimation,
  DiagonalDiv,
  DiagonalContainer,
} from "modules/org-landing/styles";

const ModalContainer = styled.div`
  top: 50%;
  left: 50%;
  height: 90%;
  width: 400px;
  display: flex;
  outline: none;
  padding: 10px 30px;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  transform: translate(-50%, -50%);
  background: ${(props) => props.background};

  @media (max-width: 440px) {
    height: 100%;
    width: calc(100% - 60px);
  }
`;

const PositionedIconButton = styled(IconButton)`
  && {
    top: 0;
    right: 0;
    position: absolute;

    &:hover {
      > svg {
        fill: ${(props) => props.hoverColor};
      }
    }

    @media (max-width: 440px) {
      top: 10px;
    }
  }
`;

const ModalBtn = styled.button`
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  background: transparent;
  transition: all 0.5s ease;
  color: ${(props) => props.color};
  border: 2px solid ${(props) => props.color};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.hoverColor};
    background: ${(props) => props.color};
  }
`;

const AboutContent = () => {
  const lang = useLocalStorageState("lang", {
    defaultValue: "en",
  })[0];

  return (
    <React.Fragment>
      {lang === "en" ? (
        <React.Fragment>
          Founded in 2020 and based in Nicosia, the Non-Profit Organisation{" "}
          <b>Make Music Cyprus</b> has quickly established itself as a key
          player in the cultural landscape of Cyprus. Despite its relatively
          short history, the organisation has already built meaningful
          partnerships and collaborations with institutions, organizations,
          researchers, educators, and artists across Cyprus, the United Kingdom,
          Asia, Europe, Africa, and the Americas.
          <br />
          <br />
          Our team brings together professionals from diverse backgrounds, from
          culture to engineering, and from media to events management. Each one
          brings their expertise to organizing quality festivals, concerts,
          academic research projects, and artistic initiatives.
        </React.Fragment>
      ) : (
        <React.Fragment>
          Ο Μη Κερδοσκοπικός Οργανισμός Make Music Cyprus ιδρύθηκε το 2020 με
          έδρα τη Λευκωσία και καθιερώθηκε γρήγορα ως βασικός παράγοντας στο
          πολιτιστικό τοπίο της Κύπρου. Παρά τη σχετικά σύντομη ιστορία του, ο
          οργανισμός έχει ήδη οικοδομήσει σημαντικές συνεργασίες με ιδρύματα,
          οργανισμούς, ερευνητές, εκπαιδευτικούς και καλλιτέχνες σε όλη την
          Κύπρο, το Ηνωμένο Βασίλειο, την Ασία, την Ευρώπη, την Αφρική και την
          Αμερική.
          <br />
          <br />Η ομάδα μας αποτελείται από επαγγελματίες με διαφορετικά
          υπόβαθρα, από τον πολιτισμό μέχρι τη μηχανική και από τα χώρο του
          μάρκετινγκ και της διαφήμισης μέχρι τη διοργάνωση εκδηλώσεων. Ο
          καθένας μας προσφέρει τις εμπειρίες και την τεχνογνωσία του στη
          διοργάνωση ποιοτικών φεστιβάλ, συναυλιών, ακαδημαϊκών ερευνητικών
          προγραμμάτων και καλλιτεχνικών πρωτοβουλιών.
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const MissonContent = () => {
  const lang = useLocalStorageState("lang", {
    defaultValue: "en",
  })[0];

  return (
    <React.Fragment>
      {lang === "en" ? (
        <React.Fragment>
          <b>Vision</b>
          <br />
          To foster a vibrant, creative community where music and the arts bring
          people together, inspire joy, and create lasting cultural connections.
          <br />
          <br />
          <b>Mission</b>
          <br />
          To support and promote Cyprus’ music scene by organizing world-class
          festivals and events that celebrate local talent, connect Cyprus to
          the global music landscape, and deliver exceptional musical
          experiences.
        </React.Fragment>
      ) : (
        <React.Fragment>
          <b>Όραμα</b>
          <br />
          Να προωθήσουμε μια ζωντανή, δημιουργική κοινότητα όπου η μουσική και
          οι τέχνες φέρνουν τους ανθρώπους κοντά, εμπνέουν χαρά και δημιουργούν
          μόνιμες πολιτιστικές σχέσεις.
          <br />
          <br />
          <b>Αποστολή</b>
          <br />Η υποστήριξη και προώθηση της μουσικής σκηνής της Κύπρου με τη
          διοργάνωση φεστιβάλ και εκδηλώσεων παγκόσμιας κλάσης που εξυμνούν τα
          τοπικά ταλέντα, συνδέουν την Κύπρο με το παγκόσμιο μουσικό τοπίο και
          προσφέρουν εξαιρετικές μουσικές εμπειρίες.
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export function OrgLandingModule() {
  const scrollRef = React.useRef(null);
  const scrollableContainerCoords = useScroll(scrollRef);

  const isTouchDeviceVar = isTouchDevice();

  const [openAboutModal, setOpenAboutModal] = React.useState(false);
  const [openMissionModal, setOpenMissionModal] = React.useState(false);
  const [openContactModal, setOpenContactModal] = React.useState(false);

  const [lang, setLang] = useLocalStorageState("lang", {
    defaultValue: "en",
  });

  const image2Loaded = useBgImageSkeletonLoader({
    imageUrl: "/gallery/cy/68.jpg",
    elementId: "diagonal-div-projects",
  });
  const image3Loaded = useBgImageSkeletonLoader({
    imageUrl: "/gallery/breach-console.jpg",
    elementId: "diagonal-div-contact",
  });

  function onMobileChevronClick() {
    const target = document.getElementById("diagonal-container");
    if (target) {
      target.scrollTo({
        left: target.clientWidth,
        behavior: "smooth",
      });
    }
  }

  function onLanguageChange(e) {
    setLang(e.target.value);
  }

  return (
    <React.Fragment>
      <Modal
        open={openAboutModal}
        onClose={() => setOpenAboutModal(false)}
        BackdropProps={{
          style: {
            background: "rgba(0,0,0,0.7)",
          },
        }}
      >
        <ModalContainer background={colors.orange1}>
          <PositionedIconButton
            hoverColor={colors.black}
            onClick={() => setOpenAboutModal(false)}
          >
            <CloseIcon htmlColor={colors.white} fontSize="large" />
          </PositionedIconButton>
          <h2
            style={{
              margin: 0,
              fontSize: 38,
              color: colors.white,
              textAlign: "center",
            }}
          >
            {lang === "en" ? "Organisation" : "Οργανισμός"}
          </h2>
          <div
            style={{
              fontSize: fontsizes.h4,
            }}
          >
            <AboutContent />
          </div>
          <ModalBtn
            color={colors.white}
            hoverColor={colors.black}
            onClick={() => setOpenAboutModal(false)}
          >
            Close
          </ModalBtn>
        </ModalContainer>
      </Modal>
      <Modal
        open={openMissionModal}
        onClose={() => setOpenMissionModal(false)}
        BackdropProps={{
          style: {
            background: "rgba(0,0,0,0.7)",
          },
        }}
      >
        <ModalContainer background={colors.orange1}>
          <PositionedIconButton
            hoverColor={colors.black}
            onClick={() => setOpenMissionModal(false)}
          >
            <CloseIcon htmlColor={colors.white} fontSize="large" />
          </PositionedIconButton>
          <h2
            style={{
              margin: 0,
              fontSize: 38,
              color: colors.white,
              textAlign: "center",
            }}
          >
            Mission
          </h2>
          <div
            style={{
              fontSize: fontsizes.h4,
            }}
          >
            <MissonContent />
          </div>
          <ModalBtn
            color={colors.white}
            hoverColor={colors.black}
            onClick={() => setOpenMissionModal(false)}
          >
            Close
          </ModalBtn>
        </ModalContainer>
      </Modal>
      <Modal
        open={openContactModal}
        onClose={() => setOpenContactModal(false)}
        BackdropProps={{
          style: {
            background: "rgba(0,0,0,0.7)",
          },
        }}
      >
        <ModalContainer background={colors.orange1}>
          <PositionedIconButton
            hoverColor={colors.black}
            onClick={() => setOpenContactModal(false)}
          >
            <CloseIcon htmlColor={colors.white} fontSize="large" />
          </PositionedIconButton>
          <h2
            style={{
              margin: 0,
              fontSize: 38,
              color: colors.white,
              textAlign: "center",
            }}
          >
            Contact
          </h2>
          <div
            style={{
              display: "flex",
              padding: "10px 20px",
              width: "fit-content",
              fontSize: fontsizes.h4,
              flexDirection: "column",
              background: colors.orange1,
            }}
          >
            <div>
              <b>Email</b>:{" "}
              <a
                href="mailto:info@makemusiccyprus.org"
                style={{
                  color: colors.black,
                }}
              >
                info@makemusiccyprus.org
              </a>
            </div>
            <div>
              <div style={{ width: "100%", height: 10 }} />
              <b>Telephone</b>:{" "}
              <a
                href="tel:0035770000216"
                style={{
                  color: colors.black,
                }}
              >
                +357 700 00 216
              </a>
            </div>
          </div>
          <ModalBtn
            color={colors.white}
            hoverColor={colors.black}
            onClick={() => setOpenMissionModal(false)}
          >
            Close
          </ModalBtn>
        </ModalContainer>
      </Modal>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "relative",
          background: colors.black,
        }}
      >
        <BgAnimation>
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
          <MusicNoteIcon fontSize="large" />
        </BgAnimation>
        <DiagonalContainer count={4} id="diagonal-container" ref={scrollRef}>
          <DiagonalDiv
            to="/"
            background={colors.orange1}
            style={{ cursor: "default" }}
            onClick={() => {
              if (isTouchDeviceVar) {
                setOpenAboutModal(true);
              }
            }}
          >
            <span
              style={{
                top: 0,
                left: 0,
                zIndex: 0,
                width: "100%",
                height: "50%",
                position: "absolute",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${logo})`,
              }}
              className="hide-on-hover"
            />
            <span
              id="show-on-hover"
              style={{
                padding: 20,
                color: colors.black,
                width: "calc(100% - 40px)",
              }}
            >
              <AboutContent />
            </span>
            <span id="header-on-hover">
              {lang === "en" ? "Organisation" : "Οργανισμός"}
            </span>
            <div>
              <div>{lang === "en" ? "Organisation" : "Οργανισμός"}</div>
            </div>
          </DiagonalDiv>
          <DiagonalDiv to="/projects" id="diagonal-div-projects">
            {!image2Loaded && (
              <Skeleton
                width="100%"
                height="100%"
                animation="wave"
                variant="rectangular"
                sx={{ bgcolor: "grey.900" }}
              />
            )}
            <span id="header-on-hover">
              {lang === "en" ? "Projects" : "Δράσεις"}
            </span>
            <div>
              <div>{lang === "en" ? "Projects" : "Δράσεις"}</div>
            </div>
          </DiagonalDiv>
          <DiagonalDiv
            to="/"
            background={colors.orange1}
            style={{ cursor: "default" }}
            onClick={() => {
              if (isTouchDeviceVar) {
                setOpenMissionModal(true);
              }
            }}
          >
            <span
              style={{
                left: 10,
                bottom: -30,
                position: "absolute",
                transform: "scale(0.3)",
              }}
            >
              <Container showMobile>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </Container>
            </span>
            <span
              style={{
                top: 0,
                right: 112,
                position: "absolute",
                transform: "scale(0.3)",
              }}
            >
              <Container opposite showMobile>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </Container>
            </span>
            <span
              id="show-on-hover"
              style={{
                padding: 20,
                color: colors.black,
                width: "calc(100% - 40px)",
              }}
            >
              <MissonContent />
            </span>
            <span id="header-on-hover">
              {lang === "en" ? "Mission" : "Αποστολή"}
            </span>
            <div>
              <div>{lang === "en" ? "Mission" : "Αποστολή"}</div>
            </div>
          </DiagonalDiv>
          <DiagonalDiv
            to="#"
            style={{ cursor: "default" }}
            onClick={() => {
              if (isTouchDeviceVar) {
                setOpenContactModal(true);
              }
            }}
            id="diagonal-div-contact"
          >
            {!image3Loaded && (
              <Skeleton
                width="100%"
                height="100%"
                animation="wave"
                variant="rectangular"
                sx={{ bgcolor: "grey.900" }}
              />
            )}
            <span
              id="show-on-hover"
              style={{
                left: 0,
                top: "40%",
                zIndex: 0,
                padding: 20,
                width: "80%",
                height: "100%",
                color: colors.black,
                position: "absolute",
                background: "transparent",
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "10px 20px",
                  width: "fit-content",
                  flexDirection: "column",
                  background: colors.orange1,
                }}
              >
                <div>
                  <b>Email</b>:{" "}
                  <a
                    href="mailto:info@makemusiccyprus.org"
                    style={{
                      color: colors.black,
                    }}
                  >
                    info@makemusiccyprus.org
                  </a>
                </div>
                <div>
                  <div style={{ width: "100%", height: 10 }} />
                  <b>Telephone</b>:{" "}
                  <a
                    href="tel:0035770000216"
                    style={{
                      color: colors.black,
                    }}
                  >
                    +357 700 00 216
                  </a>
                </div>
              </div>
            </span>
            <span id="header-on-hover">
              {lang === "en" ? "Contact" : "Επικοινωνία"}
            </span>
            <div>
              <div>{lang === "en" ? "Contact" : "Επικοινωνία"}</div>
            </div>
          </DiagonalDiv>
        </DiagonalContainer>
      </div>
      <div
        onClick={onMobileChevronClick}
        style={{
          right: 30,
          bottom: 30,
          height: 24,
          zIndex: 100,
          position: "fixed",
          cursor: "pointer",
          display: scrollableContainerCoords.x === 0 ? "block" : "none",
          animation: "scroll-indicator-horizontal 1.5s infinite linear",
        }}
        className="scroll-horizontal-button"
      >
        <Chevron fill={colors.white} style={{ transform: "scale(1.5)" }} />
      </div>
      <select
        value={lang}
        onChange={onLanguageChange}
        style={{
          top: "20px",
          right: "20px",
          outline: "none",
          position: "fixed",
          color: colors.white,
          borderColor: colors.white,
          background: "transparent",
        }}
      >
        <option value="en">EN</option>
        <option value="el">ΕΛ</option>
      </select>
      <Footer />
    </React.Fragment>
  );
}
