/* base */
import React from "react";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import styled from "styled-components";
// import styled from "styled-components";
// import ReactPlayer from "react-player";
import { useQuery } from "graphql-hooks";
import { isInViewport } from "utils/isInViewport";
import { useTitle, useUpdateEffect } from "react-use";
// import { useTitle, useUpdateEffect } from "react-use";
import useLocalStorageState from "use-local-storage-state";

/* components */
import { colors } from "theme/colors";
import { CloseIcon } from "icons/Close";
import { Footer } from "components/footer";
import { LoaderComponent } from "components/loader";
import { HomeModule1 } from "modules/home/modules/module1";
import { HomeModule2 } from "modules/home/modules/module2";
import { HomeModule3 } from "modules/home/modules/module3";
import { HomeModule4 } from "modules/home/modules/module4";
import { HomeModule5 } from "modules/home/modules/module5";
import { HomeModule6 } from "modules/home/modules/module6";
import { HomeModule7 } from "modules/home/modules/module7";
import { FunkyTitle } from "modules/org-projects/project-pages/breach/2024";
import { misc } from "theme/misc";

const VideoDiv = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);

  // > div {
  //   touch-events: none;
  //   pointer-events: none;
  // }

  > svg {
    top: 2vh;
    right: 2vw;
    z-index: 2002;
    cursor: pointer;
    position: absolute;
    transform: scale(2);

    > path {
      fill: #fff;
    }
  }
`;

const BreachModal = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  outline: none;
  padding: 10px 30px;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  background: ${colors.breachPurple};

  > svg {
    top: 2vh;
    right: -48px;
    z-index: 2002;
    cursor: pointer;
    position: absolute;
    transform: scale(2);
    transition: all 0.3s ease-in-out;

    > path {
      fill: #fff;
    }

    &:hover {
      > path {
        fill: ${colors.breachPink};
      }
    }
  }

  > button {
    top: 35%;
    color: #fff;
    z-index: 2002;
    right: -185px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 800;
    padding: 10px 20px;
    position: absolute;
    border: 4px solid #fff;
    background: transparent;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #fff;
      color: ${colors.breachOrange};
      border-color: ${colors.breachOrange};
    }
  }

  @media (max-width: 440px) {
    height: 100%;
    width: calc(100% - 60px);

    > svg {
      right: 2vw;
    }
  }
  @media (max-width: 900px) {
    > button {
      position: initial;
    }
  }
`;

const BASE_HOMEPAGE_QUERY = `query Homepage($lang: SiteLocale) {
  homepage {
    titleSegment1(locale: $lang)
    titleSegment2(locale: $lang)
    module2TitleSegment1(locale: $lang)
    module2TitleSegment2(locale: $lang)
    module2ContentSegment1(locale: $lang)
    module2ContentSegment2(locale: $lang)
    module2Slogan(locale: $lang)
    module2Button(locale: $lang)
    module2Image {
      url
    }
    module3Video {
      providerUid
    }
    module4TitleSegment1(locale: $lang)
    module4TitleSegment2(locale: $lang)
    module4TitleSegment3(locale: $lang)
    module4ContentSegment1(locale: $lang)
    module4ContentSegment2(locale: $lang)
    module4Slogan(locale: $lang)
    module4Button(locale: $lang)
    module4Image {
      url
    }
    module5TitleSegment1(locale: $lang)
    module5TitleSegment2(locale: $lang)
    module5Images {
      url
    }
    module5Button(locale: $lang)
  }
}`;

export function HomeModule() {
  useTitle("Make Music Cyprus");

  // const [showVideo, setShowVideo] = React.useState(
  //   sessionStorage.getItem("show-video") === "0" ? false : true
  // );
  const [openBreachModal, setOpenBreachModal] = React.useState(
    sessionStorage.getItem("open-breachmodal") === "0" ? false : true
  );

  // const lang = useLocalStorageState("lang", {
  //   defaultValue: "en",
  // })[0];
  const lang = "en";
  const { loading, data, refetch } = useQuery(BASE_HOMEPAGE_QUERY, {
    variables: { lang: lang || "en" },
  });

  function onScroll() {
    const anchors = document.getElementsByClassName("anchor");
    if (anchors) {
      const element1 = document.getElementById("1-third-h2");
      if (element1) {
        const result1 = isInViewport(element1);
        if (result1) {
          anchors[0].classList.add("title-line-1-3");
        } else {
          anchors[0].classList.remove("title-line-1-3");
        }
      }
      const element2 = document.getElementById("2-third-h2");
      if (element2) {
        const result2 = isInViewport(element2);
        if (result2) {
          anchors[1].classList.add("title-line-2");
        } else {
          anchors[1].classList.remove("title-line-2");
        }
      }
      const element3 = document.getElementById("3-third-h3");
      if (element3) {
        const result3 = isInViewport(element3);
        if (result3) {
          anchors[2].classList.add("title-line-1-3");
        } else {
          anchors[2].classList.remove("title-line-1-3");
        }
      }
      const element4 = document.getElementById("4-third-h3");
      if (element4) {
        const result4 = isInViewport(element4);
        if (result4) {
          anchors[3].classList.add("title-line-1-3");
        } else {
          anchors[3].classList.remove("title-line-1-3");
        }
      }
    }
  }

  // useUpdateEffect(() => {
  //   if (!showVideo) {
  //     sessionStorage.setItem("show-video", "0");
  //   }
  // }, [showVideo]);

  useUpdateEffect(() => {
    if (!openBreachModal) {
      sessionStorage.setItem("open-breachmodal", "0");
    }
  }, [openBreachModal]);

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useUpdateEffect(() => {
    refetch({
      lang: lang || "en",
    });
  }, [lang]);

  // React.useEffect(() => {
  //   if (openBreachModal) {
  //     document.body.style.overflowY = "hidden";
  //   } else {
  //     document.body.style.overflowY = "auto";
  //   }
  // }, [openBreachModal]);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Make Music Cyprus fills within the walls Nicosia’s outdoor spaces and venues with hundreds of live concerts and music activities, all free and open to the public."
        />
      </Helmet>
      {/* {showVideo && (
        <VideoDiv>
          <CloseIcon onClick={() => setShowVideo(false)} />
          <ReactPlayer
            controls
            volume={1}
            muted={true}
            height="auto"
            playing={true}
            width={window.innerWidth * 0.9}
            onEnded={() => {
              setShowVideo(false);
            }}
            url="https://www.facebook.com/makemusiccyprus/videos/569100324584858"
          />
        </VideoDiv>
      )} */}
      {/* {openBreachModal && (
        <VideoDiv>
          <BreachModal>
            <CloseIcon onClick={() => setOpenBreachModal(false)} />
            <div
              style={{
                width: "100%",
                height: "100%",
                background: "url(/posters/breach-lineup.jpg)",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
            <button
              onClick={() => {
                setOpenBreachModal(false);
                window.open("/breach-festival", "_blank");
              }}
            >
              MORE INFO
            </button>
          </BreachModal>
        </VideoDiv>
      )}
      {!openBreachModal && (
        <div
          style={{
            right: 20,
            width: 150,
            bottom: 20,
            height: 48,
            fontSize: 20,
            zIndex: 2001,
            color: "#fff",
            fontWeight: 800,
            display: "flex",
            borderRadius: 25,
            cursor: "pointer",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: misc.boxshadow,
            background: colors.breachPurple,
          }}
          onClick={() => setOpenBreachModal(true)}
        >
          <FunkyTitle style={{ marginTop: -2 }}>
            <div>B</div>
            <div>R</div>
            <div>E</div>
            <div>A</div>
            <div>C</div>
            <div>H</div>
          </FunkyTitle>
        </div>
      )} */}
      <HomeModule1
        title1={get(data, "homepage.titleSegment1", "MAKE MUSIC")}
        title2={get(data, "homepage.titleSegment2", "CYPRUS")}
      />
      <HomeModule2
        title1={get(data, "homepage.module2TitleSegment1", "WORLDWIDE")}
        title2={get(data, "homepage.module2TitleSegment2", "MUSIC FESTIVAL")}
        imageURL={get(data, "homepage.module2Image.url", null)}
        content1={get(data, "homepage.module2ContentSegment1", "")}
        content2={get(data, "homepage.module2ContentSegment2", "")}
        button={get(data, "homepage.module2Button", "ABOUT US")}
        slogan={get(
          data,
          "homepage.module2Slogan",
          "Let’s celebrate the musician in all of us!"
        )}
      />
      <HomeModule3
        videoId={get(data, "homepage.module3Video.providerUid", "ilj4sN2rQrE")}
      />
      <HomeModule4
        title1={get(
          data,
          "homepage.module4TitleSegment1",
          "COMPLETELY DIFFERENT"
        )}
        title2={get(data, "homepage.module4TitleSegment2", "FROM A TYPICAL")}
        title3={get(data, "homepage.module4TitleSegment3", "MUSIC FESTIVAL")}
        imageURL={get(data, "homepage.module4Image.url", null)}
        content1={get(data, "homepage.module4ContentSegment1", "")}
        content2={get(data, "homepage.module4ContentSegment2", "")}
        button={get(data, "homepage.module4Button", "PARTICIPATE")}
        slogan={get(
          data,
          "homepage.module4Slogan",
          "All of it is free and open to the public!"
        )}
      />
      <HomeModule5
        title1={get(data, "homepage.module5TitleSegment1", "MAKE MUSIC")}
        title2={get(data, "homepage.module5TitleSegment2", "AROUND THE WORLD")}
        images={get(data, "homepage.module5Images", []).map(
          (image) => image.url
        )}
        button={get(data, "homepage.module5Button", "GALLERY")}
      />
      <HomeModule6 />
      {/* <HomeModule7 /> */}
      <Footer />
    </React.Fragment>
  );
}
