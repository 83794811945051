/* base */
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { colors } from "theme/colors";
import { Link } from "react-router-dom";
import { fontsizes } from "theme/fontsizes";

export const GridBoxLink = styled(Link)`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.white};
  flex-direction: column;
  justify-content: center;
  font-size: ${fontsizes.h3};
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 3px ${colors.white};
  transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);

  &:hover {
    background-color: ${colors.orange1};
    box-shadow: inset 0px 0px 0px 25px ${colors.white};
  }

  @media (max-width: 1090px) {
    width: calc(33vw - 48px);
    height: calc(33vw - 48px);
  }

  @media (max-width: 899px) {
    width: calc(50vw - 48px);
    height: calc(50vw - 48px);
  }
`;

export const GridBox = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BottomSpacer = styled.div`
  @media (max-width: 899px) {
    width: 100%;
    height: 100px;
    background: #000;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const BgAnimation = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #000000;
  overflow: hidden;
  z-index: 0;

  svg {
    opacity: 0.5;
    color: #f05a23;
    position: absolute;
    animation: move;
    animation-duration: 45;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  svg:nth-child(1) {
    top: 95%;
    left: 7%;
    animation-duration: 9s;
    animation-delay: -4s;
    transform-origin: -23vw 5vh;
  }
  svg:nth-child(2) {
    top: 100%;
    left: 85%;
    animation-duration: 24s;
    animation-delay: -38s;
    transform-origin: 0vw -4vh;
  }
  svg:nth-child(3) {
    top: 27%;
    left: 48%;
    animation-duration: 15s;
    animation-delay: -39s;
    transform-origin: 24vw 7vh;
  }
  svg:nth-child(4) {
    top: 93%;
    left: 38%;
    animation-duration: 10s;
    animation-delay: -14s;
    transform-origin: 0vw -2vh;
  }
  svg:nth-child(5) {
    top: 95%;
    left: 2%;
    animation-duration: 26s;
    animation-delay: -25s;
    transform-origin: -16vw 0vh;
  }
  svg:nth-child(6) {
    top: 50%;
    left: 44%;
    animation-duration: 22s;
    animation-delay: -10s;
    transform-origin: -23vw -4vh;
  }
  svg:nth-child(7) {
    top: 19%;
    left: 24%;
    animation-duration: 30s;
    animation-delay: -10s;
    transform-origin: -8vw -4vh;
  }
  svg:nth-child(8) {
    top: 99%;
    left: 67%;
    animation-duration: 7s;
    animation-delay: -23s;
    transform-origin: 19vw 25vh;
  }
  svg:nth-child(9) {
    top: 31%;
    left: 40%;
    animation-duration: 23s;
    animation-delay: -38s;
    transform-origin: -15vw 22vh;
  }
  svg:nth-child(10) {
    top: 34%;
    left: 6%;
    animation-duration: 33s;
    animation-delay: -8s;
    transform-origin: 16vw 21vh;
  }
  svg:nth-child(11) {
    top: 34%;
    left: 37%;
    animation-duration: 50s;
    animation-delay: -3s;
    transform-origin: 12vw 10vh;
  }
  svg:nth-child(12) {
    top: 35%;
    left: 85%;
    animation-duration: 55s;
    animation-delay: -31s;
    transform-origin: -5vw -16vh;
  }
  svg:nth-child(13) {
    top: 27%;
    left: 81%;
    animation-duration: 43s;
    animation-delay: -26s;
    transform-origin: -6vw 9vh;
  }
  svg:nth-child(14) {
    top: 13%;
    left: 100%;
    animation-duration: 15s;
    animation-delay: -26s;
    transform-origin: -4vw -2vh;
  }
  svg:nth-child(15) {
    top: 69%;
    left: 1%;
    animation-duration: 18s;
    animation-delay: -46s;
    transform-origin: 6vw -10vh;
  }
  svg:nth-child(16) {
    top: 16%;
    left: 4%;
    animation-duration: 16s;
    animation-delay: -9s;
    transform-origin: -13vw 13vh;
  }
  svg:nth-child(17) {
    top: 43%;
    left: 96%;
    animation-duration: 44s;
    animation-delay: -1s;
    transform-origin: -15vw -18vh;
  }
  svg:nth-child(18) {
    top: 10%;
    left: 100%;
    animation-duration: 18s;
    animation-delay: -50s;
    transform-origin: -5vw 11vh;
  }
  svg:nth-child(19) {
    top: 58%;
    left: 32%;
    animation-duration: 38s;
    animation-delay: -29s;
    transform-origin: 1vw 15vh;
  }
  svg:nth-child(20) {
    top: 63%;
    left: 96%;
    animation-duration: 14s;
    animation-delay: -15s;
    transform-origin: -16vw -8vh;
  }
`;

export const DiagonalContainer = styled.div`
  top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  position: relative;
  flex-direction: row;
  height: calc(100% - 20px);

  > a {
    height: calc(100% - 180px);
    // height: ${(props) => 100 / props.count}%;
    width: ${(props) => 100 / props.count}%;

    &:hover {
      // height: calc(100% - ${(props) => props.count - 1} * 20px);
      width: calc(100% - ${(props) => props.count - 1} * 20px);
    }
  }
`;

export const DiagonalDiv = styled(Link)`
  display: flex;
  margin: 0 20px;
  overflow: hidden;
  // min-height: 20px;
  min-width: 100px;
  align-items: center;
  align-content: center;
  text-decoration: none;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  background: ${(props) => props.background};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1200px) {
    min-width: 200px;
  }

  > div {
    right: 20px;
    bottom: 20px;
    display: flex;
    padding: 0 5px;
    background: #000;
    position: absolute;
    visibility: visible;
    // flex-direction: row;
    // align-items: center;
    letter-spacing: 5px;
    // justify-content: center;

    > div {
      color: #fff;
      font-size: 32px;
      // line-height: 40px;
      text-transform: lowercase;
    }

    @media (max-width: 1350px) {
      > div {
        font-size: 24px;
        overflow-wrap: anywhere;
      }
    }

    @media (max-width: 1100px) {
      left: 10px;
      right: 10px;
      bottom: 10px;

      > div {
        width: 100%;
        font-size: 18px;
        text-align: center;
      }
    }
  }

  #show-on-hover {
    font-size: 1.25em;
    visibility: hidden;

    @media (max-width: 600px) {
      display: none;
    }
  }

  #header-on-hover {
    top: 20px;
    left: 20px;
    color: #fff;
    padding: 0 5px;
    font-size: 32px;
    background: #000;
    visibility: hidden;
    position: absolute;
    letter-spacing: 5px;
    text-transform: lowercase;

    @media (max-width: 600px) {
      display: none;
    }
  }

  &:hover {
    > div {
      @media (min-width: 600px) {
        visibility: hidden;
      }
    }

    #show-on-hover {
      visibility: visible;
      animation: 3s fadeIn;
      animation-fill-mode: forwards;
    }

    #header-on-hover {
      visibility: visible;
    }

    .hide-on-hover {
      display: none;
    }
  }

  position: relative;

  ${(props) =>
    !props.noTopValue
      ? `
  &:nth-of-type(1) {
    top: 0;
  }
  &:nth-of-type(2) {
    top: 40px;
  }
  &:nth-of-type(3) {
    top: 80px;
  }
  &:nth-of-type(4) {
    top: 120px;
  }
  &:nth-of-type(5) {
    top: 160px;
  }
  `
      : ""}
`;
