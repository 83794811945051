import React from "react";

export function useBgImageSkeletonLoader({ imageUrl, elementId }) {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  React.useEffect(() => {
    const element = document.getElementById(elementId);
    let preloaderImg = document.createElement("img");
    preloaderImg.src = imageUrl;

    preloaderImg.addEventListener("load", () => {
      element.style.backgroundImage = `url(${imageUrl})`;
      setImageLoaded(true);
      preloaderImg = null;
    });
  }, []);

  return imageLoaded;
}
